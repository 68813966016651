exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-entrenamientos-jsx": () => import("./../../../src/pages/entrenamientos.jsx" /* webpackChunkName: "component---src-pages-entrenamientos-jsx" */),
  "component---src-pages-eventos-jsx": () => import("./../../../src/pages/eventos.jsx" /* webpackChunkName: "component---src-pages-eventos-jsx" */),
  "component---src-pages-how-do-we-do-that-jsx": () => import("./../../../src/pages/how-do-we-do-that.jsx" /* webpackChunkName: "component---src-pages-how-do-we-do-that-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reseller-jsx": () => import("./../../../src/pages/reseller.jsx" /* webpackChunkName: "component---src-pages-reseller-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-why-trust-us-jsx": () => import("./../../../src/pages/why-trust-us.jsx" /* webpackChunkName: "component---src-pages-why-trust-us-jsx" */)
}

